import { createClient } from '@supabase/supabase-js'

//const supabaseUrl = 'https://tnetkunhdvjwzzofnvwp.supabase.co'
//const supabaseKey = process.env.SUPABASE_KEY

//const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
//const supabaseKey = process.env.REACT_APP_ANON_KEY
const supabaseUrl = "https://tnetkunhdvjwzzofnvwp.supabase.co"
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InRuZXRrdW5oZHZqd3p6b2ZudndwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI1Mjc4NzYsImV4cCI6MjAyODEwMzg3Nn0.wSDvJFfquhMLoUnF2HZk_aHoFg5BAClIuXjGsc_S9Lo"

//Console.log(supabaseUrl);
//Console.log(supabaseKey);

const supabase = createClient(supabaseUrl, supabaseKey)


export default supabase
